<template>
  <div class="login">
    <div class="inner-login">
      <span class="welcome">{{ $t("welcomAgain") }}</span>
      <button class="logout" @click="signOut()">تسجيل الخروج</button>
      <h1>{{ $t("changePassword") }}</h1>
      <div class="inner-confirm-input">
        <svg
          @click="showHidePass('oldPassword')"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22.172 19"
        >
          <path
            id="Icon_ionic-md-eye-off"
            data-name="Icon ionic-md-eye-off"
            d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z"
            transform="translate(-2.25 -4.5)"
            fill="#848484"
          />
        </svg>
        <vs-input
          data-vv-validate-on="blur"
          v-validate="'required|min:6'"
          type="password"
          name="old-password"
          ref="oldPassword"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          :label-placeholder="$t('oldPassword')"
          v-model="oldPassword"
          class="w-full mt-8 oldPassword"
        />
        <span class="text-danger text-sm">{{
          errors.first("oldPassword")
        }}</span>
      </div>
      <div class="inner-confirm-input">
        <svg
          @click="showHidePass('newPassword')"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22.172 19"
        >
          <path
            id="Icon_ionic-md-eye-off"
            data-name="Icon ionic-md-eye-off"
            d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z"
            transform="translate(-2.25 -4.5)"
            fill="#848484"
          />
        </svg>
        <vs-input
          data-vv-validate-on="blur"
          v-validate="'required|min:6'"
          type="password"
          ref="password"
          name="newPassword"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          :label-placeholder="$t('newPassword')"
          v-model="newPassword"
          class="w-full mt-8 newPassword"
        />
        <span class="text-danger text-sm">{{
          errors.first("newPassword")
        }}</span>
      </div>
      <div class="inner-confirm-input">
        <svg
          @click="showHidePass('returnNewPassword')"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 22.172 19"
        >
          <path
            id="Icon_ionic-md-eye-off"
            data-name="Icon ionic-md-eye-off"
            d="M13.338,8.5a5.026,5.026,0,0,1,5.042,5,4.812,4.812,0,0,1-.361,1.831l2.944,2.919a11.828,11.828,0,0,0,3.459-4.75A11.94,11.94,0,0,0,9.316,6.7l2.177,2.162A4.985,4.985,0,0,1,13.338,8.5ZM3.259,5.772l2.3,2.281.465.46A11.772,11.772,0,0,0,2.25,13.5a11.958,11.958,0,0,0,15.5,6.66l.426.421L21.131,23.5l1.282-1.272L4.536,4.5ZM8.831,11.3l1.564,1.549a2.834,2.834,0,0,0-.079.648,3.008,3.008,0,0,0,3.023,3,2.844,2.844,0,0,0,.653-.079l1.564,1.549A5.016,5.016,0,0,1,8.3,13.49,4.942,4.942,0,0,1,8.831,11.3Zm4.344-.777,3.177,3.152.02-.158a3.008,3.008,0,0,0-3.023-3Z"
            transform="translate(-2.25 -4.5)"
            fill="#848484"
          />
        </svg>
        <vs-input
          data-vv-validate-on="blur"
          v-validate="'required|min:6'"
          type="password"
          ref="returnNewPassword"
          name="returnNewPassword"
          icon-no-border
          icon="icon icon-lock"
          icon-pack="feather"
          @input="checkPassword()"
          :label-placeholder="$t('returnNewPassword')"
          v-model="returnNewPassword"
          class="w-full mt-8 returnNewPassword"
        />
        <span class="text-danger text-sm">{{
          checkPass
            ? errors.first("returnNewPassword")
            : $t("identicalPassword")
        }}</span>
      </div>
      <div class="flex flex-wrap justify-between mt-5">
        <button
          class="btn-login w-full"
          :class="{ 'opacity-btn1': !validateFormNewPassword }"
          @click="submitNewPassword($event)"
        >
          {{ load ? "جاري التغيير" : $t("changePassword") }}
        </button>
      </div>
      <vs-alert
        v-if="err_msg"
        :active="err_msg"
        color="danger"
        class="mt-5"
        icon="new_releases"
      >
        <span>{{ err_msg }}</span>
      </vs-alert>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import loadSpiner from "@/views/components/table-components/SpinnerColor2";

export default {
  data() {
    return {
      load: false,
      oldPassword: "",
      newPassword: "",
      returnNewPassword: "",
      checkPass: true,
    };
  },
  components: {
    loadSpiner,
  },
  computed: {
    ...mapGetters("auth", ["error_msg"]),
    ...mapGetters("userData", ["err_msg"]),
    validateFormNewPassword() {
      if (
        this.oldPassword !== "" &&
        this.newPassword !== "" &&
        this.returnNewPassword !== "" &&
        this.checkPassword()
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions("auth", ["login", "logout"]),
    ...mapActions("userData", ["changePassword"]),
    showHidePass(className) {
      let el = document.querySelector(`.${className} input`)
      if (el.type == "password") {
        el.type = "text";
      } else {
        el.type = "password";
      }
    },
    checkPassword() {
      if (this.newPassword != this.returnNewPassword) {
        this.checkPass = false;
        return false;
      } else {
        this.checkPass = true;
        return true;
      }
    },
    async submitNewPassword(event) {
      if (this.checkPassword()) {
        this.load = true;
        let userData = localStorage.getItem("userData");
        userData = JSON.parse(userData);
        axios.defaults.headers.common["Authorization"] =
          `Bearer ` + userData.token;
        const obj = {
          oldPassword: this.oldPassword,
          password: this.newPassword,
        };
        await this.changePassword(obj);
        this.load = false;
        this.$router.push({ name: "Home" });
      }
    },
    async signOut() {
      await this.logout();
      this.$socket.disconnect();
      this.$router.push({ name: "page-login" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/vuexy/_login.scss";
</style>

<style lang="scss">
@import "@/assets/scss/vuexy/_variables.scss";
.remember {
  .checkbox_x {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 0.125rem;
    border: 0.0625rem solid $mainColor1 !important;
    margin-right: 0.5rem;
  }
}
.vs-con-input {
  .vs-input--input.hasIcon {
    height: 39px;
    padding-left: 2.2rem !important;
  }
  .vs-input--input.hasIcon:not(.icon-after-input) + .vs-input--placeholder {
    padding-left: 2.2rem !important;
  }
}

.isFocus {
  .vs-placeholder-label {
    margin-top: 5px;
  }
}
</style>
